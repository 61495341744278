"use strict";
document.addEventListener('DOMContentLoaded', () => {
    const showMoreButton = document.querySelector('.infoAdvantages__showMoreButton');
    const showMoreButtonText = showMoreButton?.querySelector('.infoAdvantages__showMoreButtonText');
    const showMoreIcon = document.querySelector('.infoAdvantages__showMoreButtonIcon');
    const advantagesItems = document.querySelectorAll('.infoAdvantages__item');
    showMoreButton?.addEventListener('click', () => {
        advantagesItems.forEach((item, index) => {
            if (index > 3) {
                item.classList.toggle('hidden');
            }
        });
        const isExpanded = showMoreButtonText?.textContent?.includes('СКРЫТЬ');
        if (showMoreButtonText)
            showMoreButtonText.textContent = isExpanded ? 'ПОКАЗАТЬ БОЛЬШЕ' : 'СКРЫТЬ';
        showMoreIcon?.classList.toggle('rotate-180');
    });
});
